<template>
  <div>
    <Navbar title="Researchers" :hideSearch="false" />
    <div class="app-body my-5 py-5">
      <div class="columns">
        <div class="column">
          <p class="text-subtitle bold">Researchers Lounge</p>
        </div>
        <div class="column is-narrow">
          <b-tooltip v-if="false" position="is-left" type="is-light" multilined>
            <b-button icon-left="lock" type="is-info" disabled>
              Request for Data
            </b-button>
            <template v-slot:content>
              This feature is available for
              <b>Researcher PRO</b> account.
            </template>
          </b-tooltip>
          <b-button v-if="false" icon-left="plus" type="is-info">
            Request for Data
          </b-button>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <p>
            Researchers Lounge takes advantage of knowledge and expertise to
            connect researchers with global resources. It is an innovation of
            sorts by making use of modern tools of communication in library
            information sciences.
          </p>
        </div>
      </div>

      <div v-if="currentUser.id && currentUser.role != 'free'">
        <p class="text-subtitle bold py-3 pt-5">HEC Digital Resources</p>
        <div class="p-5 box bg-dark-light has-text-centered mt-5">
          <div class="columns is-multiline">
            <div class="column is-3">
              <a
                :href="
                  currentUser.id && currentUser.role == 'free'
                    ? 'javascript:void(0);'
                    : 'https://www.tandfonline.com/'
                "
                :target="
                  currentUser.id && currentUser.role == 'free'
                    ? '_self'
                    : '_blank'
                "
              >
                <div class="box box-anim has-text-centered">
                  <img src="@/assets/logos/tf.png" style="height: 100px" />
                  <p class="mt-4 subtitle bold">Taylor & Francis</p>
                </div>
              </a>
            </div>
            <div class="column is-3">
              <a
                :href="
                  currentUser.id && currentUser.role == 'free'
                    ? 'javascript:void(0);'
                    : 'https://www.proquest.com/pqdtglobal/?accountid=135034&parentSessionId=b%2BPvO0VLz54LgNtq6nh8tj3BIRZ16Q7HQhbfRaYETqs%3D'
                "
                :target="
                  currentUser.id && currentUser.role == 'free'
                    ? '_self'
                    : '_blank'
                "
              >
                <div class="box box-anim has-text-centered">
                  <img
                    src="@/assets/logos/proquest.png"
                    style="height: 100px"
                  />
                  <p class="mt-4 subtitle bold">ProQuest</p>
                </div>
              </a>
            </div>
            <div class="column is-3">
              <a
                :href="
                  currentUser.id && currentUser.role == 'free'
                    ? 'javascript:void(0);'
                    : 'https://pubsonline.informs.org/'
                "
                :target="
                  currentUser.id && currentUser.role == 'free'
                    ? '_self'
                    : '_blank'
                "
              >
                <div class="box box-anim has-text-centered">
                  <img
                    src="@/assets/logos/informs.png"
                    style="padding: 20px 0px"
                  />
                  <p class="mt-4 subtitle bold">Informs</p>
                </div>
              </a>
            </div>
            <div class="column is-3">
              <a
                :href="
                  currentUser.id && currentUser.role == 'free'
                    ? 'javascript:void(0);'
                    : 'https://compass.astm.org/'
                "
                :target="
                  currentUser.id && currentUser.role == 'free'
                    ? '_self'
                    : '_blank'
                "
              >
                <div class="box box-anim has-text-centered">
                  <img src="@/assets/logos/astm.svg" style="height: 100px" />
                  <p class="mt-4 subtitle bold">ASTM</p>
                </div>
              </a>
            </div>
            <div class="column is-3">
              <a
                :href="
                  currentUser.id && currentUser.role == 'free'
                    ? 'javascript:void(0);'
                    : 'https://link.springer.com/'
                "
                :target="
                  currentUser.id && currentUser.role == 'free'
                    ? '_self'
                    : '_blank'
                "
              >
                <div class="box box-anim has-text-centered">
                  <img
                    src="@/assets/logos/springer.png"
                    style="height: 100px"
                  />
                  <p class="mt-4 subtitle bold">Springer</p>
                </div>
              </a>
            </div>
            <div class="column is-3">
              <a
                :href="
                  currentUser.id && currentUser.role == 'free'
                    ? 'javascript:void(0);'
                    : 'https://onlinelibrary.wiley.com/'
                "
                :target="
                  currentUser.id && currentUser.role == 'free'
                    ? '_self'
                    : '_blank'
                "
              >
                <div class="box box-anim has-text-centered">
                  <img src="@/assets/logos/wiley.jpg" style="height: 100px" />
                  <p class="mt-4 subtitle bold">Wiley Blackwell</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <p class="text-subtitle bold py-3 pt-5">International E-Newspapers</p>
        <div
          v-if="this.magazines"
          class="p-5 box bg-dark-light has-text-centered mt-5"
        >
          <div v-if="magazines" class="columns is-multiline">
            <div class="column is-3">
              <router-link
                :to="{
                  name: 'MagazineReader',
                  params: {
                    name: this.magazines[0].title,
                    url: this.magazines[0].attachmentURL
                  }
                }"
              >
                <div class="box box-anim has-text-centered">
                  <img src="@/assets/logos/nyt.png" style="height: 100px" />
                  <p class="mt-4 subtitle bold">The New York Times</p>
                </div>
              </router-link>
            </div>
            <div class="column is-3">
              <router-link
                v-if="this.magazines"
                :to="{
                  name: 'MagazineReader',
                  params: {
                    name: this.magazines[1].title,
                    url: this.magazines[1].attachmentURL
                  }
                }"
              >
                <div class="box box-anim has-text-centered">
                  <img src="@/assets/logos/wsj.png" style="height: 100px" />
                  <p class="mt-4 subtitle bold">The Wall Street Journal</p>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <p class="text-subtitle bold py-3 pt-5">External Resources</p>
        <div
          v-if="this.magazines"
          class="p-5 box bg-dark-light has-text-centered mt-5"
        >
          <div class="columns is-multiline">
            <div class="column is-3">
              <a href="https://www.jstor.org/">
                <div class="box box-anim has-text-centered">
                  <img src="@/assets/logos/jstor.png" style="height: 100px" />
                  <p class="mt-4 subtitle bold">JSTOR</p>
                </div>
              </a>
            </div>
            <!-- <div class="column is-3">
            <router-link
              :to="{
                name: 'ResearcherResources',
                query: { externalResourceType: 'TF' }
              }"
            >
              <div class="box box-anim has-text-centered">
                <img src="@/assets/logos/tf.png" style="height:100px" />
                <p class="mt-4 subtitle bold">Taylor & Francis</p>
              </div>
            </router-link>
          </div> -->

            <div class="column is-3">
              <router-link
                v-if="this.magazines"
                :to="{
                  name: 'MagazineReader',
                  params: {
                    name: this.magazines[2].title,
                    url: this.magazines[2].attachmentURL
                  }
                }"
              >
                <div class="box box-anim has-text-centered">
                  <img src="@/assets/logos/fa.jpg" style="height: 100px" />
                  <p class="mt-4 subtitle bold">Foreign Affairs</p>
                </div>
              </router-link>
            </div>
            <div class="column is-3">
              <router-link
                v-if="this.magazines"
                :to="{
                  name: 'MagazineReader',
                  params: {
                    name: this.magazines[3].title,
                    url: this.magazines[3].attachmentURL
                  }
                }"
              >
                <div class="box box-anim has-text-centered">
                  <img src="@/assets/logos/fp.png" style="height: 100px" />
                  <p class="mt-4 subtitle bold">Foreign Policy</p>
                </div>
              </router-link>
            </div>
            <div class="column is-3">
              <router-link
                :to="{
                  name: 'ResearcherResources',
                  query: { externalResourceType: 'OTHERS' }
                }"
              >
                <div class="box box-anim has-text-centered">
                  <img src="@/assets/img/book_icon.svg" style="height: 100px" />
                  <p class="mt-4 subtitle bold">Downloadable Resources</p>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <p class="text-subtitle bold py-3 pt-5">Search Engines</p>
        <div class="p-5 box bg-dark-light has-text-centered mt-5">
          <div class="columns is-multiline">
            <div class="column is-3">
              <router-link
                :to="{
                  name: 'NYT'
                }"
              >
                <div class="box box-anim has-text-centered">
                  <img src="@/assets/logos/nyt.png" style="height: 100px" />
                  <p class="mt-4 subtitle bold">The New York Times</p>
                </div>
              </router-link>
            </div>
            <div class="column is-3">
              <router-link
                :to="{
                  name: 'Guardian'
                }"
              >
                <div class="box box-anim has-text-centered">
                  <img
                    src="@/assets/logos/guardian.png"
                    style="height: 100px"
                  />
                  <p class="mt-4 subtitle bold">The Guardian</p>
                </div>
              </router-link>
            </div>
            <div class="column is-3">
              <a
                :href="
                  currentUser.id && currentUser.role == 'free'
                    ? 'javascript:void(0);'
                    : 'https://www.refseek.com/'
                "
                :target="
                  currentUser.id && currentUser.role == 'free'
                    ? '_self'
                    : '_blank'
                "
              >
                <div class="box box-anim has-text-centered">
                  <img src="@/assets/logos/refseek.png" style="height: 100px" />
                  <p class="mt-4 subtitle bold">Refseek</p>
                </div>
              </a>
            </div>
            <div class="column is-3">
              <a
                :href="
                  currentUser.id && currentUser.role == 'free'
                    ? 'javascript:void(0);'
                    : 'https://internationalaffairsresources.com/'
                "
                :target="
                  currentUser.id && currentUser.role == 'free'
                    ? '_self'
                    : '_blank'
                "
              >
                <div class="box box-anim has-text-centered">
                  <img src="@/assets/logos/wwwinternational.jpg" class="my-2" />
                  <p class="mt-4 subtitle bold">
                    International Affairs Resources
                  </p>
                </div>
              </a>
            </div>
            <div v-if="false" class="column is-3">
              <router-link
                :to="{
                  name: 'Outline'
                }"
              >
                <div class="box box-anim has-text-centered">
                  <img src="@/assets/logos/outline.svg" style="height: 100px" />
                  <p class="mt-4 subtitle bold">Outline</p>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <b-message type="is-info">
          <div class="columns is-vcentered">
            <div class="column">
              <b-icon icon="lock" class="pr-2" size="is-small"></b-icon>
              Premium Content - Login/Upgrade your account to Researcher to gain
              access
            </div>
          </div>
        </b-message>
        <div class="mt-2">
          <p class="text-subtitle bold py-3 pt-5">HEC Digital Resources</p>
          <div class="p-5 box bg-dark-light has-text-centered mt-5">
            <div class="columns is-multiline">
              <div class="column is-3">
                <a @click="openPremiumModal">
                  <div class="box box-anim has-text-centered">
                    <img src="@/assets/logos/tf.png" style="height: 100px" />
                    <p class="mt-4 subtitle bold">Taylor & Francis</p>
                  </div>
                </a>
              </div>
              <div class="column is-3">
                <a @click="openPremiumModal">
                  <div class="box box-anim has-text-centered">
                    <img
                      src="@/assets/logos/proquest.png"
                      style="height: 100px"
                    />
                    <p class="mt-4 subtitle bold">ProQuest</p>
                  </div>
                </a>
              </div>
              <div class="column is-3">
                <a @click="openPremiumModal">
                  <div class="box box-anim has-text-centered">
                    <img
                      src="@/assets/logos/informs.png"
                      style="padding: 20px 0px"
                    />
                    <p class="mt-4 subtitle bold">Informs</p>
                  </div>
                </a>
              </div>
              <div class="column is-3">
                <a @click="openPremiumModal">
                  <div class="box box-anim has-text-centered">
                    <img src="@/assets/logos/astm.svg" style="height: 100px" />
                    <p class="mt-4 subtitle bold">ASTM</p>
                  </div>
                </a>
              </div>
              <div class="column is-3">
                <a @click="openPremiumModal">
                  <div class="box box-anim has-text-centered">
                    <img
                      src="@/assets/logos/springer.png"
                      style="height: 100px"
                    />
                    <p class="mt-4 subtitle bold">Springer</p>
                  </div>
                </a>
              </div>
              <div class="column is-3">
                <a @click="openPremiumModal">
                  <div class="box box-anim has-text-centered">
                    <img src="@/assets/logos/wiley.jpg" style="height: 100px" />
                    <p class="mt-4 subtitle bold">Wiley Blackwell</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <p class="text-subtitle bold py-3 pt-5">International E-Newspapers</p>
          <div
            v-if="this.magazines"
            class="p-5 box bg-dark-light has-text-centered mt-5"
          >
            <div class="columns is-multiline">
              <div class="column is-3">
                <a @click="openPremiumModal">
                  <div class="box box-anim has-text-centered">
                    <img src="@/assets/logos/nyt.png" style="height: 100px" />
                    <p class="mt-4 subtitle bold">The New York Times</p>
                  </div>
                </a>
              </div>
              <div class="column is-3">
                <a @click="openPremiumModal">
                  <div class="box box-anim has-text-centered">
                    <img src="@/assets/logos/wsj.png" style="height: 100px" />
                    <p class="mt-4 subtitle bold">The Wall Street Journal</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <p class="text-subtitle bold py-3 pt-5">External Resources</p>
          <div
            v-if="this.magazines"
            class="p-5 box bg-dark-light has-text-centered mt-5"
          >
            <div class="columns is-multiline">
              <div class="column is-3">
                <a @click="openPremiumModal">
                  <div class="box box-anim has-text-centered">
                    <img src="@/assets/logos/jstor.png" style="height: 100px" />
                    <p class="mt-4 subtitle bold">JSTOR</p>
                  </div>
                </a>
              </div>
              <!-- <div class="column is-3">
            <router-link
              :to="{
                name: 'ResearcherResources',
                query: { externalResourceType: 'TF' }
              }"
            >
              <div class="box box-anim has-text-centered">
                <img src="@/assets/logos/tf.png" style="height:100px" />
                <p class="mt-4 subtitle bold">Taylor & Francis</p>
              </div>
            </router-link>
          </div> -->

              <div class="column is-3">
                <a @click="openPremiumModal">
                  <div class="box box-anim has-text-centered">
                    <img src="@/assets/logos/fa.jpg" style="height: 100px" />
                    <p class="mt-4 subtitle bold">Foreign Affairs</p>
                  </div>
                </a>
              </div>
              <div class="column is-3">
                <a @click="openPremiumModal">
                  <div class="box box-anim has-text-centered">
                    <img src="@/assets/logos/fp.png" style="height: 100px" />
                    <p class="mt-4 subtitle bold">Foreign Policy</p>
                  </div>
                </a>
              </div>
              <div class="column is-3">
                <a @click="openPremiumModal">
                  <div class="box box-anim has-text-centered">
                    <img
                      src="@/assets/img/book_icon.svg"
                      style="height: 100px"
                    />
                    <p class="mt-4 subtitle bold">Downloadable Resources</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <p class="text-subtitle bold py-3 pt-5">Search Engines</p>
          <div class="p-5 box bg-dark-light has-text-centered mt-5">
            <div class="columns is-multiline">
              <div class="column is-3">
                <a @click="openPremiumModal">
                  <div class="box box-anim has-text-centered">
                    <img src="@/assets/logos/nyt.png" style="height: 100px" />
                    <p class="mt-4 subtitle bold">The New York Times</p>
                  </div>
                </a>
              </div>
              <div class="column is-3">
                <a @click="openPremiumModal">
                  <div class="box box-anim has-text-centered">
                    <img
                      src="@/assets/logos/guardian.png"
                      style="height: 100px"
                    />
                    <p class="mt-4 subtitle bold">The Guardian</p>
                  </div>
                </a>
              </div>
              <div class="column is-3">
                <a @click="openPremiumModal">
                  <div class="box box-anim has-text-centered">
                    <img
                      src="@/assets/logos/refseek.png"
                      style="height: 100px"
                    />
                    <p class="mt-4 subtitle bold">Refseek</p>
                  </div>
                </a>
              </div>
              <div class="column is-3">
                <a @click="openPremiumModal">
                  <div class="box box-anim has-text-centered">
                    <img
                      src="@/assets/logos/wwwinternational.jpg"
                      class="my-2"
                    />
                    <p class="mt-4 subtitle bold">
                      International Affairs Resources
                    </p>
                  </div>
                </a>
              </div>
              <div v-if="false" class="column is-3">
                <router-link
                  :to="{
                    name: 'Outline'
                  }"
                >
                  <div class="box box-anim has-text-centered">
                    <img
                      src="@/assets/logos/outline.svg"
                      style="height: 100px"
                    />
                    <p class="mt-4 subtitle bold">Outline</p>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <b-modal v-model="showPremiumModal" has-modal-card>
      <div class="modal-card">
        <section v-if="true" class="modal-card-body p-5">
          <div class="columns">
            <div class="column has-text-centered">
              <p class="has-text-info" style="font-size:48px">
                <i class="mdi mdi-lock"></i>
              </p>
              <p class="has-text-info title">Premium Content</p>
              <p class="has-text-info subtitle">
                Create/Upgrade to Researcher Account to gain access
              </p>
              <router-link :to="{ name: 'Subscriptions' }" class="my-5">
                <b-button
                  type="is-info"
                  label="Create/Upgrade to Researcher Account"
                />
              </router-link>
            </div>
          </div>
        </section>
      </div>
    </b-modal>
    <b-modal v-model="showResearcherBanner" has-modal-card>
      <div class="modal-card">
        <section v-if="true" class="modal-card-body p-5">
          <div class="columns">
            <div class="column has-text-centered">
              <img src="@/assets/researcher_banner.jpg" />
            </div>
          </div>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Navbar from "@/components/app/Navbar";
import Footer from "@/components/app/Footer";
import api from "@/services/dataService";

export default {
  name: "Researchers",
  components: { Navbar, Footer },
  data() {
    return {
      magazines: undefined,
      isLoading: false,
      category: "image",
      sort: "title-asc",
      sortName: "Title (A-Z)",
      showSideMenu: false,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        perPage: 5
      },
      showPremiumModal: false,
      showResearcherBanner: false
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    isPortrait() {
      if (window.innerWidth <= 768) {
        return true;
      } else {
        return false;
      }
    }
  },
  async created() {
    api.get("/magazine/magazine/").then(res => {
      this.magazines = res.data;
    });
    if (
      this.currentUser.role == "paid" ||
      this.currentUser.role == "ipri" ||
      this.currentUser.role == "admin"
    ) {
      this.showResearcherBanner = true;
    }
  },
  methods: {
    openPremiumModal() {
      this.showPremiumModal = true;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

.box-anim:hover {
  transition: transform 125ms;
  transform: scale(1.05);
  -webkit-box-shadow: 0 0.5em 1em -0.125em #808080;
  box-shadow: 0 0.5em 1em -0.125em #808080;
}
</style>
